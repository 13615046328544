<template>
  <v-select
    v-model="selected"
    :chips="multiple"
    :disabled="disabled"
    :item-text="itemText"
    :item-value="itemValue"
    :items="items"
    :loading="loading"
    :multiple="multiple"
    v-bind="$attrs"
    return-object
    @change="(val) => $emit('change', val)"
    ref="select"
  >
    <template v-for="slot of Object.keys($slots)" v-slot:[slot]>
      <slot :name="slot" />
    </template>
    <template v-if="multiple" v-slot:item="{ active, item, attrs, on }">
      <v-list-item v-bind="attrs" v-on="on">
        <template v-slot:default="{ active }">
          <v-list-item-action>
            <v-checkbox :input-value="active" color="primary"></v-checkbox>
          </v-list-item-action>
          <v-list-item-content>
            <slot :item="item" name="item" v-bind="attrs" v-on="on">{{ item[itemText] }}</slot>
          </v-list-item-content>
        </template>
      </v-list-item>
    </template>
    <template v-else v-slot:item="{ item }">
      <v-list-item-content>
        <slot :item="item" name="item">{{ item[itemText] }}</slot>
      </v-list-item-content>
    </template>
    <template v-if="multiple" v-slot:selection="{ item, index }">
      <v-chip class="ma-1" close small @click:close="closeChip(index)">
        <span class="chip-text">
          <slot :item="item" name="selection">{{ item[itemText] }}</slot>
        </span>
      </v-chip>
    </template>
    <template v-else v-slot:selection="{ item }">
      <span>
        <slot :item="item" name="selection">{{ item[itemText] }}</slot>
      </span>
    </template>
    <template v-slot:append-item>
      <div
        v-intersect.quiet="{
          handler: onIntersect,
          options: {
            threshold: 1,
          },
        }"
        class="text-center grey--text"
      >
        <span v-show="loading">Завантаження ...</span>
      </div>
    </template>
  </v-select>
</template>

<script>
import { isArray } from 'lodash'
import isEqual from 'lodash/isEqual'

export default {
  name: 'PagingSelect',

  inheritAttrs: false,

  props: {
    value: {
      validator: (value) => typeof value === 'object' || typeof value === 'number' || typeof value === 'string' || value === null,
      default: () => null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    optionsMeta: {
      type: Object,
      default: () => null,
    },
    optionsParams: {
      type: Object,
      default: () => null,
    },
    itemValue: {
      type: String,
      default: 'value',
    },
    itemText: {
      type: String,
      default: 'label',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      page: 0,
      loading: false,
      items: [],
      meta: null,
      selected: null,
    }
  },

  async created() {
    await this.initialize()
  },

  watch: {
    value: {
      handler(val, old) {
        if (isEqual(val, old)) return
        if (val) {
          if (isArray(val)) {
            for (const item of val) {
              if (this.items.findIndex((i) => i[this.itemValue] === item[this.itemValue]) === -1) {
                this.items.push(item)
              }
            }
          } else {
            if (this.items.findIndex((i) => i[this.itemValue] === val[this.itemValue]) === -1) {
              this.items.push(val)
            }
          }
        }
        if (val && typeof val === 'number' && !this.items.find((item) => item[this.itemValue] === val)) {
          this.items = [val, ...this.items.filter((item) => item !== val)]
        }
        this.selected = val
      },
      deep: true,
    },
    optionsParams() {
      this.page = 0
      this.loadNext()
    },
    optionsMeta: {
      handler(val) {
        if (val.page === 1) {
          this.items = [...this.options]
          this.page = 1
        }
      },
      deep: true,
    },
  },

  methods: {
    async loadNext() {
      this.loading = true
      this.page++
      const payload = {
        ...this.optionsParams.payload,
        page: this.page,
      }
      await this.optionsParams.loadingFunction(payload)
      this.$set(this.items, this.items.length, this.options)
      this.meta = { ...this.optionsMeta }
      this.items = this.items.flat()
      this.loading = false
    },

    async initialize() {
      if (this.options.length && this.optionsMeta && this.optionsMeta.page === 1) {
        this.items = [...this.options]
        this.meta = { ...this.optionsMeta }
        this.page = 1
      } else {
        await this.loadNext()
      }
      if (this.value && typeof this.value === 'object') {
        if (isArray(this.value)) {
          this.items = [
            ...this.value,
            ...this.items.filter((item) => !this.value.find((val) => val[this.itemValue] === item[this.itemValue])),
          ]
        } else {
          if (this.value[this.itemValue]) {
            this.items = [this.value, ...this.items.filter((item) => item[this.itemValue] !== this.value[this.itemValue])]
          }
        }
      }
      if (this.value && typeof this.value !== 'object' && !this.items.find((item) => item[this.itemValue] === this.value)) {
        this.items = [this.value, ...this.items.filter((item) => item !== this.value)]
      }
      this.selected = this.value
    },

    async onIntersect(entries) {
      if (!this.meta || (entries[0].intersectionRatio === 1 && (!this.page || this.page < this.meta.total_pages))) {
        await this.loadNext()
      }
    },

    closeChip(index) {
      this.selected.splice(index, 1)
      this.$emit('change', this.selected)
    },
  },
}
</script>
